<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 col-md-auto mx-md-auto col-lg-10 col-xl-7 outer-card">
        <div class="card border-info">

          <div class="card-header text-white bg-nav_header">
            <div class="row">
              <div class="col-7 col-sm-8">
                <span class="new_my_h5">WEBテスト　{{ examination? examination.exam_name: '' }}　{{ currentExamPartSetCaption }}</span>
              </div>
              <!--
              <div class="col-5 col-sm-4 text-right my_16px">
                <div v-if="currentMasterExaminationPart">画面{{ currentPageNo }} / 全{{ currentMasterExaminationPart.page_count }}画面</div>
              </div>
              -->
            </div>
          </div><!-- card-header -->

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <template v-if="currentMasterExaminationPart&&currentPageNo&&!showNextExamPartStartingPage">

              <div class="row _mt-2">
                <div class="col-9 col-xl-10 pr-1 pt-3">
                  <TimerGaugeBar ref="refPageTimer" :isDebug="isDebug" v-model="currentPageRemainingSec" />
                </div>
                <div class="col-3 col-xl-2 pl-0 text-right">
                  <ProgressPieChart :isDebug="isDebug" :examinationPart="currentMasterExaminationPart" :currentPageNo="currentPageNo" />
                </div>
              </div>

              <div class="row">
                <div class="col-12 mt-2" id="examination_parts_wrapper">
                  <div v-if="isDebug" class="debug_str">検査のメインコンポーネント（{{ examPartMainComponent }}） examPartRemainingSec=[{{ examPartRemainingSec }}]</div>
                  <component :is="examPartMainComponent" ref="refExamPartComponent" :isDebug="isDebug"
                              :examinationPart="currentMasterExaminationPart" :currentPageNo="currentPageNo" :canEdit="false" :showDescription="false"
                              v-model="currentMasterExaminationPart.all_page_params_set"
                              @input="onInput"
                  />
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-right">
                  <!-- テストの為に前画面に遷移可とするならコメント解除 -->
                  <!-- <button v-if="currentPageNo>1" class="btn btn-header_color mx-1 my-1" @click.prevent="onPreMovePage(currentPageNo-1)">前の画面へ</button> -->
                  <button v-if="currentPageNo<currentMasterExaminationPart.page_count" class="btn btn-header_color mx-1 my-1" @keydown.prevent @click.prevent="onPreMovePage(currentPageNo+1)">次の画面へ</button>
                  <button v-if="!isLastExamPart&&isLastPage" class="btn btn-header_color mx-1 my-1" @keydown.prevent @click.prevent="onPreMoveToNextExamPart()">次の画面へ<!-- 次の検査へ --></button>
                  <button v-if="isLastExamPart&&isLastPage" class="btn btn-header_color mx-1 my-1" @keydown.prevent @click.prevent="onPreFinishAll()">試験結果画面へ<!-- すべてを送信して終了する --></button>
                </div>
              </div>

            </template><!-- v-if="currentMasterExaminationPart&&currentPageNo&&!showNextExamPartStartingPage" -->

            <template v-if="showNextExamPartStartingPage">
              <div class="row mt-2">
                <div class="col-10 offset-1">
                  ⾮⾔語検査に移ります<br/>
                  <br/>
                  開始ボタンをクリックしてください。<br/>
                  <br/>
                  クリックしない場合でもこの画⾯表⽰後、⼀定時間経過後に、⾃動的に次の画⾯に変わります。<br/>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-10 offset-1 text-right">
                  <button ref="refStartNextExamPartButton" class="btn btn-header_color mx-1 my-1 px-4" @click.prevent="onMoveToNextExamPart2()">開始</button>
                </div>
              </div>
            </template><!-- v-if="showNextExamPartStartingPage" -->

            <div class="row mt-4">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
import ProgressPieChart from '../../common/components/ProgressPieChart';
import TimerGaugeBar from '../../common/components/TimerGaugeBar';
import wa1_t1 from    '../../common/components/examination_parts/WA-1/本テスト/検査１';
import wa1_t2 from    '../../common/components/examination_parts/WA-1/本テスト/検査２';
import wa1_p1_t1 from '../../common/components/examination_parts/WA-1/練習１/検査１';
import wa1_p1_t2 from '../../common/components/examination_parts/WA-1/練習１/検査２';
import wa1_p2_t1 from '../../common/components/examination_parts/WA-1/練習２/検査１';
import wa1_p2_t2 from '../../common/components/examination_parts/WA-1/練習２/検査２';
const LogHeader = 'Exam.vue';

export default {
  //PageTitle: 'Webテスト',
  mixins: [commonMixin],
  components: {
    ProgressPieChart,
    TimerGaugeBar,
    wa1_t1, wa1_t2, wa1_p1_t1, wa1_p1_t2, wa1_p2_t1, wa1_p2_t2,
  },
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      resourceApi,
      schoolExamination: null,
      examination: null,
      student: null,
      currentExamPartIdx: null,
      currentPageNo: null,
      examPartRemainingSec: null,
      currentPageRemainingSec: null,
      examPartTimerId: null,
      examPartCutOffTime: null,
      lastReportedData: null,
      showNextExamPartStartingPage: false,
      testCaptions: {//Home,Exam,Resultで同一の定義
        本テスト: '',
        復習１: '復習利用①',
        復習２: '復習利用②',
        復習３: '復習利用③',
        復習４: '復習利用④',
        練習１: '練習版①',
        練習２: '練習版②',
      },
      isDebug: false,//デバッグ表示用（本番false）
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true/*, 'データを取得しています...'*/);
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        //全ての検査が終了している場合はhomeにリダイレクト
        console.log(`examPartSet.started_at/finished_at=[${this.examPartSet.started_at}][${this.examPartSet.finished_at}]`);
        //【「全ての検査が終了」の判定方法】
        //・本テスト（復習を含む） → examPartSet.finished_atがセット済み
        //・練習版 → 同上 ※但し、次回の「開始」と見分けが付かない
        if (!this.examPartSet.is_practice) {//（復習を含む）本テスト？
          if (this.examPartSet.finished_at) {
            console.log('redirecting exam to home');
            this.$router.push({ name: 'home' });
            return;
          }
        } else {//練習版？
          //チェックしない（できない）
        }

        //現在の検査（のインデックス）の確定
        console.log(`examPartSet.id_current_sc_ex_student_ex_prt_exam_part=[${this.examPartSet.id_current_sc_ex_student_ex_prt_exam_part}] examPartSet.current_exam_part_idx=[${this.examPartSet.current_exam_part_idx}]`);
        //「２回目以降の練習の開始」時のみ、current_exam_part_idx（と、その導出元となるid_current_sc_ex_student_ex_prt_exam_part）にはnullがセットされているので補正（先頭の検査をセット）が必要
        //（↑id_current_sc_ex_student_ex_prt_exam_partは、開始通知によってサーバー側でも正しい値がセットされる）
        //上記以外（「（復習を含む）本テストの開始」「１回目の練習の開始」「（全ての）再開」）はサーバーから送られた値をそのまま使用可能
        //【「２回目以降の練習の開始（＝前回の練習が完了済み）」の判定方法】
        //examPartSet.started_at、examPartSet.finished_atが共にセット済み、かつcurrent_exam_part_idx（とid_current_sc_ex_student_ex_prt_exam_part）がNULL
        if (this.examPartSet.is_practice) {//練習版？
          if (this.examPartSet.started_at && this.examPartSet.finished_at && !this.examPartSet.current_exam_part_idx) {
            this.examPartSet.id_current_sc_ex_student_ex_prt_exam_part = this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[0].id;
            this.examPartSet.current_exam_part_idx = 0;
            console.log(`MODIFIED examPartSet.id_current_sc_ex_student_ex_prt_exam_part=[${this.examPartSet.id_current_sc_ex_student_ex_prt_exam_part}] examPartSet.current_exam_part_idx=[${this.examPartSet.current_exam_part_idx}]`);
          }
        }
        this.currentExamPartIdx = this.examPartSet.current_exam_part_idx;
        console.log(`this.currentExamPartIdx=[${this.currentExamPartIdx}]`);

        //試験開始または再開
        console.log(`this.currentMyExaminationPart.started_at/finished_at=[${this.currentMyExaminationPart.started_at}][${this.currentMyExaminationPart.finished_at}]`);
        //【「開始」の判定方法】
        //・本テスト（復習を含む） → currentMyExaminationPart.started_atがNULL（finished_atもNULL）
        //・練習版（１回目） → 同上
        //・練習版（２回目以降） → currentMyExaminationPart.started_atもfinished_atも（直近回の値が）セット済み
        //【「再開」の判定方法】
        //・本テスト（復習を含む） → currentMyExaminationPart.started_atがセット済み、finished_atはNULL
        //・練習版（１回目） → 同上
        //・練習版（２回目以降） → 同上
        if ( ! (this.currentMyExaminationPart.started_at && !this.currentMyExaminationPart.finished_at) ) {//検査開始？（＝「上記の再開」でない？）

          //サーバーへ検査の開始を通知
          this.setSavingStatus(true);
          this.putStudentExamPartToStart()
          .then((response) => {
            console.log(`[${LogHeader}] created() putStudentExamPartToStart()-then START`, response);
            let scExStudentExPrtSetExamPart = response.data.sc_ex_student_ex_prt_set_exam_part;
            window.token = sessionStorage.token = response.data.access_token;//トークンリフレッシュ
            this.setSavingStatus(false);

            //各ページは各answerが（単一文字列解答であろうとも）arrayであることを前提としているのに、
            //新規開始の時に（answer_setの中身が構築されていなくてarrayになっていなくて）エラーになる（＝画面が表示されない）対策
            this.reflectExamPartAnswerSet(scExStudentExPrtSetExamPart.answer_set, this.currentMasterExaminationPart);//再開時と同じ答案一式復元処理

            this.$nextTick(() => {//この時点では（onMovePage内で参照する）$refsの準備ができていないので$nextTickを使う

              this.onMovePage(scExStudentExPrtSetExamPart.current_page_no/*=1*/);//これで初めて画面が表示される（＆ページタイマー開始）

              this.startExamPartTimer(this.currentMasterExaminationPart.time_limit_sec);//検査単位タイマー開始

            });//$nextTick

            console.log(`[${LogHeader}] created() putStudentExamPartToStart()-then END`);
          }).catch((error) => {
            console.error(`[${LogHeader}] created() putStudentExamPartToStart()-catch START`, error);
            this.setSavingStatus(false);
            this.setResult(error.response);
            console.error(`[${LogHeader}] created() putStudentExamPartToStart()-catch END`, error);
          });//↑開始通知

        } else {//検査再開？

          //サーバーへ検査の再開を通知
          this.setSavingStatus(true);
          this.putStudentExamPartToRestart()
          .then((response) => {
            console.log(`[${LogHeader}] created() putStudentExamPartToRestart-then START`, response);
            let scExStudentExPrtSetExamPart = response.data.sc_ex_student_ex_prt_set_exam_part;
            window.token = sessionStorage.token = response.data.access_token;//トークンリフレッシュ
            this.setSavingStatus(false);

            this.reflectExamPartAnswerSet(scExStudentExPrtSetExamPart.answer_set, this.currentMasterExaminationPart);//現在の検査の答案一式を復元

            this.$nextTick(() => {//この時点では（onMovePage内で参照する）$refsの準備ができていないので$nextTickを使う

              this.onMovePage(//これで初めて画面が表示される（＆ページタイマー開始）
                scExStudentExPrtSetExamPart.current_page_no,//ページ位置の復元
                scExStudentExPrtSetExamPart.current_page_remaining_sec);//ページ内残り時間の復元

              //【注意】 current_page_remaining_sec と exam_part_remaining_sec は連動できていない
              //current_page_remaining_sec はページ毎の制限時間管理に徹する
              //exam_part_remaining_sec はサーバーへの通知間隔の管理に徹する（負値になろうが通知し続けてＯＫ）

              this.startExamPartTimer(scExStudentExPrtSetExamPart.exam_part_remaining_sec/*残り時間*/);//検査単位タイマー開始

            });//$nextTick

            console.log(`[${LogHeader}] created() putStudentExamPartToRestart-then END`);
          }).catch((error) => {
            console.error(`[${LogHeader}] created() putStudentExamPartToRestart-catch START`, error);
            this.setSavingStatus(false);
            this.setResult(error.response);
            console.error(`[${LogHeader}] created() putStudentExamPartToRestart-catch END`, error);
          });//↑再開通知

        }//if-else

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });//↑getSpecial(url)

  },//created()
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  beforeRouteLeave(to, from, next) {
    console.log(`[${LogHeader}] beforeRouteLeave() CALLED`);
    if (this.$refs.refPageTimer) {
      this.$refs.refPageTimer.Stop();//ページタイマー停止
    }
    this.stopExamPartTimer();//検査単位タイマー停止
    next();
  },
  computed: {
    examPartSet() { return this.student&&this.student.organized_exam_part_sets? this.student.organized_exam_part_sets[this.$route.params.examPartSetId]: null; },
    currentMasterExaminationPart() { return this.examPartSet&&this.currentExamPartIdx>=0? this.examPartSet.master_exam_parts[this.currentExamPartIdx]: null; },
    currentMyExaminationPart() { return this.examPartSet&&this.currentExamPartIdx>=0? this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[this.currentExamPartIdx]: null; },
    examPartMainComponent() { return this.currentMasterExaminationPart? this.currentMasterExaminationPart.main_component_name: null; },
    currentPageName() { return (this.currentMasterExaminationPart&&this.currentPageNo)? this.currentMasterExaminationPart.page_names[this.currentPageNo-1]: null; },
    currentPageParams() { return this.currentMasterExaminationPart&&this.currentPageName? this.currentMasterExaminationPart.all_page_params_set[this.currentPageName]: null; },
    isLastPage() { return this.currentMasterExaminationPart? (this.currentPageNo == this.currentMasterExaminationPart.page_count ): false; },
    isLastExamPart() { return this.examPartSet? (this.currentExamPartIdx == 1 ): false; },
    currentExamPartSetCaption() { return this.testCaptions[this.$route.params.examPartSetId]; },
  },
  methods: {
    startExamPartTimer(examPartRemainingSec) {
      //console.log(`[${LogHeader}] startExamPartTimer(${examPartRemainingSec}) START`);
      this.stopExamPartTimer();//念の為
      this.examPartRemainingSec = examPartRemainingSec;
      this.examPartCutOffTime = (new Date()).getTime() + examPartRemainingSec * 1000;
      this.examPartTimerId = setInterval(() => {
        this.examPartRemainingSec = Math.ceil((this.examPartCutOffTime - (new Date()).getTime()) / 1000);
        //console.log(`[${LogHeader}] examPartRemainingSec=[${this.examPartRemainingSec}]`);
        if (this.examPartRemainingSec <= 0) {
          //console.log(`[${LogHeader}] examPartRemainingSec is UP !!`);
          //clearInterval忘れ防止の為にタイマーを止める
          //this.stopExamPartTimer();//負値になってもサーバーへの通知は止めたくないので廃止 → 停止はbeforeRouteLeaveに任せる
        }
      }, 1000);
      console.log(`[${LogHeader}] startExamPartTimer(${examPartRemainingSec}) started timer (id=[${this.examPartTimerId}])`);
      //console.log(`[${LogHeader}] startExamPartTimer(${examPartRemainingSec}) END`);
    },
    stopExamPartTimer() {
      //console.log(`[${LogHeader}] stopExamPartTimer() START`);
      if (this.examPartTimerId) {
        clearInterval(this.examPartTimerId);
        console.log(`[${LogHeader}] stopExamPartTimer() stopped timer (id=[${this.examPartTimerId}])`);
        this.examPartTimerId = null;
        //停止後の参照にも対応できるよう、 examPartRemainingSec, examPartCutOffTime は保持
      }
      //console.log(`[${LogHeader}] stopExamPartTimer() END`);
    },
    reflectExamPartAnswerSet(answerSet, examinationPart) {
      //（サーバーに保存されていた）答案一式をall_page_params_setに埋め込む ※admin/views/master/examination_parts/Edit.vueのafterCreatedGetThen()と似たロジック
      //このロジックはサーバー側で持つことが望ましいが、extractExamPartAnswerSet()との対比のためにクライアント側に置いている
      console.log(`[${LogHeader}] reflectExamPartAnswerSet() START`);
      //examinationPart.all_page_params_set内の各問題のanswerに復元
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        let pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            let questionParams = pageParams[questionKey];
            questionParams.answer = answerSet[questionKey] ?? [];
            //console.log(`[${pageKey}][${questionKey}]=[${questionParams.answer}]`);//デバッグ用（本番コメントアウト）
          }
        }
      }
      console.log(`[${LogHeader}] reflectExamPartAnswerSet() END`, examinationPart.all_page_params_set);
    },
    extractExamPartAnswerSet(examinationPart) {
      //現在のall_page_params_setから回答・答案を抽出して答案一式を作成する ※admin/views/master/examination_parts/Edit.vueのmakeSaveParams()と似たロジック
      //このロジックをサーバー側で持つことも検討したが、一時保存のサーバー負荷を考慮するとクライアントで処理する方が望ましい
      console.log(`[${LogHeader}] extractExamPartAnswerSet() START`);
      let answerSet = {};
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        const pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            const questionParams = pageParams[questionKey];
            const answer = questionParams.answer ?? [];
            //answer.sort();//入力順に格納されているのでソート → checkboxだけでなく、順序に意味のある複数文字列（例：分子、分母）回答もあるので厳禁（checkboxの場合は下位でソートしておく）
            //console.log(`[${pageKey}][${questionKey}]=[${answer}]`);//デバッグ用（本番コメントアウト）

            //全回答一律の補正（カンマ除去、半角英数字化）
            //XXX 回答の補正を必要最小限にとどめるにはquestionParamsにフラグ等の情報が必要（あるいは各ページ(.vue)で補正する）
            const adjAnswer = answer.map((value/*, index, array*/) => {
              const adjusted = this.wideAlphaNumToHalf(this.removeComma(this.trimWhiteSpace(value)));//220115 trimWhiteSpace追加
              if (adjusted !== value) {
                console.log(`adjusted [${value}]→[${adjusted}]`);
              }
              return adjusted;
            }, this);

            answerSet[questionKey] = adjAnswer;
            console.log(`[${pageKey}][${questionKey}]=[${answerSet[questionKey]}]`);
          }
        }
      }
      console.log(`[${LogHeader}] extractExamPartAnswerSet() END`, answerSet);
      return answerSet;
    },
    putStudentExamPartToStart() {//currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToStart() CALLED`);
      const url = `sc_ex_student_ex_prt_set_exam_part/start`;
      return this.resourceApi.putSpecial(url, {
        id_sc_ex_student_exam_part_set: this.examPartSet.id,
        id_sc_ex_student_ex_prt_set_exam_part: this.currentMyExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toISOString(),//or toLocaleString() サーバー側でserver_client_initial_time_diff_secの算出に使うのでtoISOString()の方がよいと思う
      });
    },
    putStudentExamPartToRestart() {//currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToRestart() CALLED`);
      const url = `sc_ex_student_ex_prt_set_exam_part/restart`;
      return this.resourceApi.putSpecial(url, {
        id_sc_ex_student_exam_part_set: this.examPartSet.id,
        id_sc_ex_student_ex_prt_set_exam_part: this.currentMyExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toISOString(),//or toLocaleString() サーバー側でserver_client_initial_time_diff_secの算出に使うのでtoISOString()の方がよいと思う
      });
    },
    processPutStudentExamPartWithTmpData(skipIfSame) {//他と違ってthen,catchまでひとまとめにしてある
      console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData(${skipIfSame}) CALLED`);

      const answerSet = this.extractExamPartAnswerSet(this.currentMasterExaminationPart);

      //（サーバー負荷を考慮して）前回と同じデータ（かつ前回送信からclient_report_interval_sec*10秒未満）なら送信しない
      if (skipIfSame) {
        const reportingData = {
          current_page_no: this.currentPageNo,
          answer_set_str: JSON.stringify(answerSet),
          last_reported_at_msec: Date.now(),
        };
        if (this.lastReportedData &&
          reportingData.current_page_no == this.lastReportedData.current_page_no &&
          reportingData.answer_set_str == this.lastReportedData.answer_set_str &&
          reportingData.last_reported_at_msec - this.lastReportedData.last_reported_at_msec < this.currentMasterExaminationPart.client_report_interval_sec * 1000) {
          console.log(`SKIP Reporting (page(now/prev)=[${reportingData?.current_page_no??''}][${this.lastReportedData?.current_page_no??''}] sec(now/prev)=[${reportingData.last_reported_at_msec/1000}][${this.lastReportedData.last_reported_at_msec/1000}])`);
          return;
        }
        console.log(`NOT SKIP Reporting (page(now/prev)=[${reportingData?.current_page_no??''}][${this.lastReportedData?.current_page_no??''}] sec(now/prev)[${reportingData.last_reported_at_msec/1000}][${this.lastReportedData?this.lastReportedData.last_reported_at_msec/1000:''}])`);
        this.lastReportedData = reportingData;
      }

      const url = `sc_ex_student_ex_prt_set_exam_part/tmp`;
      this.resourceApi.putSpecial(url, {
        id_sc_ex_student_exam_part_set: this.examPartSet.id,
        id_sc_ex_student_ex_prt_set_exam_part: this.currentMyExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toISOString(),//or toLocaleString() サーバー側で時間計算に使う可能性があるのでtoISOString()の方がよいと思う
        exam_part_remaining_sec: this.examPartRemainingSec,
        current_page_no: this.currentPageNo,
        current_page_remaining_sec: this.currentPageRemainingSec,
        answer_set: answerSet,
      })
      .then((response) => {
        console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-then START`, response);
        console.log(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] processPutStudentExamPartWithTmpData() putSpecial()-catch END`, error);
      });
    },
    putStudentExamPartToFinish() {//currentExaminationPartが正しいことが前提
      console.log(`[${LogHeader}] putStudentExamPartToFinish() CALLED`);
      const url = `sc_ex_student_ex_prt_set_exam_part/finish`;
      return this.resourceApi.putSpecial(url, {
        id_sc_ex_student_exam_part_set: this.examPartSet.id,
        id_sc_ex_student_ex_prt_set_exam_part: this.currentMyExaminationPart.id,
        last_reported_client_datetime_str: (new Date()).toISOString(),//or toLocaleString() サーバー側で時間計算に使う可能性があるのでtoISOString()の方がよいと思う
        exam_part_remaining_sec: this.examPartRemainingSec,
        current_page_no: this.currentPageNo,
        current_page_remaining_sec: this.currentPageRemainingSec,
        answer_set: this.extractExamPartAnswerSet(this.currentMasterExaminationPart),
      });
    },
    onPopState() {
      console.log(`[${LogHeader}] onPopState() CALLED`);
      window.history.pushState(null, null, null);
      alert('ブラウザの「戻る」は使用できません。');
    },
    onBeforeUnload(event) {
      console.log(`[${LogHeader}] onBeforeUnload() CALLED`);
      //ブラウザにタブクローズの確認ダイアログを出してもらう see https://developer.mozilla.org/ja/docs/Web/API/Window/beforeunload_event
      event.preventDefault();
      event.returnValue = '';
    },
    onInput(e) {
      //↓デバッグ用（本番コメントアウト）
      //console.log(`[${LogHeader}] onInput() CALLED`, e);
      //↑デバッグ用（本番コメントアウト）
      //↓デバッグ用（本番コメントアウト）
      //this.extractExamPartAnswerSet(this.currentMasterExaminationPart);//extractExamPartAnswerSet()内のログもコメント解除しないといけない
      //↑デバッグ用（本番コメントアウト）
    },
    canMovePage() {
      console.log(`[${LogHeader}] canMovePage() CALLED`);
      if (typeof this.$refs.refExamPartComponent.canMovePage === 'function') {
        return this.$refs.refExamPartComponent.canMovePage();
      }
      return true;
    },
    onPreMovePage(newPageNo, newTimeLimitSec) {
      console.log(`[${LogHeader}] onPreMovePage(newPageNo=[${newPageNo}], newTimeLimitSec=[${newTimeLimitSec}]) CALLED`);
      if (!this.canMovePage()) {
        return;
      }
      this.onMovePage(newPageNo, newTimeLimitSec);
    },
    onMovePage(newPageNo, newTimeLimitSec) {
      console.log(`[${LogHeader}] onMovePage(newPageNo=[${newPageNo}], newTimeLimitSec=[${newTimeLimitSec}]) CALLED`);
      this.resetError();
      this.resetStatus();

      if (this.currentPageNo) {//他のページからの遷移（not 初期表示）？
        this.$refs.refPageTimer.Stop();//ページタイマー停止
      }

      this.currentPageNo = newPageNo;//ページ変更

      this.$nextTick(() => {
        window.scrollTo(0, 0);
        this.$refs.refPageTimer.Start(//（スクロール後であることを期待して）新しいページのタイマーを開始
          this.currentPageParams.time_limit_sec,//ページの制限時間
          newTimeLimitSec!==undefined? newTimeLimitSec: this.currentPageParams.time_limit_sec);//残り時間（指定がなければページの制限時間と同じ）
      });
    },
    onPreMoveToNextExamPart() {
      console.log(`[${LogHeader}] onPreMoveToNextExamPart() CALLED`);
      if (!this.canMovePage()) {
        return;
      }
      this.onMoveToNextExamPart1();//onMoveToNextExamPart2()を直接呼ばないこと
    },
    onMoveToNextExamPart1() {
      console.log(`[${LogHeader}] onMoveToNextExamPart1() CALLED`);
      this.$refs.refPageTimer.Stop();//ページタイマー停止
      this.stopExamPartTimer();//検査単位タイマー停止
      this.resetError();
      this.resetStatus();

      //↓次の検査前画面の調整をする場合はコメント解除 ※onMoveToNextExamPart2()冒頭にもコメント解除対象箇所があるので注意
      // //putStudentExamPartToFinish()-thenの動作をシミュレート
      // this.currentPageNo = null;//いったん問題画面を消す
      // if (this.examination.exam_name.substr(0,2).toUpperCase() == 'DUMMY') {//次の試験前に画面を挟む必要のある試験種？
      //   this.showNextExamPartStartingPage = true;
      //   setTimeout(() => {
      //     console.log(`[${LogHeader}] onMoveToNextExamPart1() clicking refStartNextExamPartButton`);
      //     if (this.$refs.refStartNextExamPartButton) {
      //       this.$refs.refStartNextExamPartButton.click();//直接 onMoveToNextExamPart2() を呼ぶのと同等
      //     }
      //   }, 10 * 1000);
      // }
      // return;
      //↑ここまで

      //サーバーへ現在の検査の終了を通知
      this.setSavingStatus(true);
      this.putStudentExamPartToFinish()
      .then((response) => {
        console.log(`[${LogHeader}] onMoveToNextExamPart1() putStudentExamPartToFinish()-then START`, response);
        this.setSavingStatus(false);

        this.currentPageNo = null;//いったん問題画面を消す

        //試験種によっては次の試験前に画面を挟む必要がある
        //XXX 試験種毎の検査間画面の表示要否、表示内容、タイムアウト時間を別ファイル（common\components\examination_parts配下）に保持することも検討する
        if (this.examination.exam_name.substr(0,2).toUpperCase() == 'DUMMY') {//次の試験前に画面を挟む必要のある試験種？

          this.showNextExamPartStartingPage = true;//次の検査前画面を表示
          setTimeout(() => {
            console.log(`[${LogHeader}] onMoveToNextExamPart1() clicking refStartNextExamPartButton`);
            if (this.$refs.refStartNextExamPartButton) {
              this.$refs.refStartNextExamPartButton.click();//タイマーで開始ボタン押下（直接 onMoveToNextExamPart2() を呼ぶのと同等）
            }
          }, 10 * 1000);

        } else {

          this.onMoveToNextExamPart2();//次の検査へ

        }
        console.log(`[${LogHeader}] onMoveToNextExamPart1() putStudentExamPartToFinish()-then END`, response);
      }).catch((error) => {
        console.error(`[${LogHeader}] onMoveToNextExamPart1() putStudentExamPartToFinish()-catch START`, error);
        this.setSavingStatus(false);
        this.setResult(error.response);
        console.error(`[${LogHeader}] onMoveToNextExamPart1() putStudentExamPartToFinish()-catch END`, error);
      });//↑終了通知
    },
    onMoveToNextExamPart2() {//前半の処理（＝現在の検査の終了）を onMoveToNextExamPart1() に移した
      console.log(`[${LogHeader}] onMoveToNextExamPart2() CALLED`);

      this.showNextExamPartStartingPage = false;//（表示していた場合、）次の検査開始前の画面を消す

      //↓onMoveToNextExamPart1()で次の検査前画面の調整をする場合はコメント解除
      // alert('onMoveToNextExamPart1() 実験中の為、onMoveToNextExamPart2()を中断！！');
      // return;
      //↑ここまで

      this.currentExamPartIdx++;//次の検査

      //サーバーへ次の検査の開始を通知
      this.setSavingStatus(true);
      this.putStudentExamPartToStart()
      .then((response) => {
        console.log(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToStart()-then START`, response);
        let scExStudentExPrtSetExamPart = response.data.sc_ex_student_ex_prt_set_exam_part;
        window.token = sessionStorage.token = response.data.access_token;//トークンリフレッシュ
        this.setSavingStatus(false);

        //各ページは各answerが（単一文字列解答であろうとも）arrayであることを前提としているのに、
        //新規開始の時に（answer_setの中身が構築されていなくて）エラーになる（＝画面が表示されない）対策
        this.reflectExamPartAnswerSet(scExStudentExPrtSetExamPart.answer_set, this.currentMasterExaminationPart);

        this.onMovePage(scExStudentExPrtSetExamPart.current_page_no/*=1*/);//これで画面が再表示される（＆ページタイマー開始）

        this.startExamPartTimer(this.currentMasterExaminationPart.time_limit_sec);//検査単位タイマー開始

        console.log(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToStart()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToStart()-catch START`, error);
        this.setSavingStatus(false);
        this.setResult(error.response);
        console.error(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToStart()-catch END`, error);
      });//↑開始通知

      console.log(`[${LogHeader}] onMoveToNextExamPart2() putStudentExamPartToFinish()-then END`);
    },//onMoveToNextExamPart2()
    onPreFinishAll() {
      console.log(`[${LogHeader}] onPreFinishAll() CALLED`);
      if (!this.canMovePage()) {
        return;
      }
      this.onFinishAll();
    },
    onFinishAll() {
      console.log(`[${LogHeader}] onFinishAll() CALLED`);
      this.$refs.refPageTimer.Stop();//ページタイマー停止
      this.stopExamPartTimer();//検査単位タイマー停止
      this.resetError();
      this.resetStatus();

      //サーバーへ現在の検査（＝最終の検査）の終了を通知
      this.setSavingStatus(true);
      this.putStudentExamPartToFinish()
      .then((response) => {
        console.log(`[${LogHeader}] onFinishAll() putStudentExamPartToFinish()-then START`, response);
        this.setSavingStatus(false);

        //（タイムアップモーダルとは別に共通で）何か表示するならここ

        //結果画面へ遷移
        this.$router.push({ name: 'result', params: { examPartSetId: this.$route.params.examPartSetId, showDescAtFirst: false } });

        console.log(`[${LogHeader}] onFinishAll() putStudentExamPartToFinish()-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] onFinishAll() putStudentExamPartToFinish()-catch START`, error);
        this.setSavingStatus(false);
        this.setResult(error.response);
        console.error(`[${LogHeader}] onFinishAll() putStudentExamPartToFinish()-catch END`, error);
      });//↑終了通知

    },//onFinishAll()
  },//method
  watch: {
    currentPageRemainingSec: {
      handler(newValue, oldValue) {
        //console.log(`[${LogHeader}] watch:currentPageRemainingSec([${oldValue}]→[${newValue}]) CALLED`);//デバッグ用（本番コメントアウト）
        if (newValue != null && newValue <= 0) {//０秒になったらタイマーを止めているので１回しか通らない

          console.log(`[${LogHeader}] watch:currentPageRemainingSec([${oldValue}]→[${newValue}]) TIME IS UP !!`);
          this.$refs.refPageTimer.Stop();//ページタイマー停止

          //テストの為にページ毎のタイムアウトを無効にするならコメント解除
          // this.processPutStudentExamPartWithTmpData(false/*スキップ不可*/);//現状保存だけしておく
          // alert('タイムアウト無効化中');
          // return;

          if (this.isLastPage) {//最終ページ？

            if (this.isLastExamPart) {//最終の検査？（＝検査２？）

              //this.processPutStudentExamPartWithTmpData(false/*スキップ不可*/);//モーダル表示前に確実に現状を保存しておく ※タイムアップをモーダル表示しないなら不要

              //タイムアップモーダルを表示するならここ

              //現在の検査（＝検査２）を終了（＆結果画面へ遷移）
              this.onFinishAll();

            } else {//最終の検査以外？（＝検査１）

              //this.processPutStudentExamPartWithTmpData(false/*スキップ不可*/);//モーダル表示前に確実に現状を保存しておく ※タイムアップをモーダル表示しないなら不要

              //タイムアップモーダルを表示するならここ

              //現在の検査（＝検査１）を終了して次の検査（＝検査２）へ
              this.onMoveToNextExamPart1();//onMoveToNextExamPart2()を直接呼ばないこと

            }

          } else {//途中ページ？

            //次のページへ遷移（＆ページタイマー再セット）
            this.onMovePage(this.currentPageNo+1);

          }//if(this.isLastPage)-else

        }//if(newValue!=null&&newValue<=0)
      }//handler
    },//currentPageRemainingSec
    examPartRemainingSec: {
      handler(newValue, oldValue) {
        //console.log(`[${LogHeader}] watch:examPartRemainingSec([${oldValue}]→[${newValue}]) CALLED`);//デバッグ用（本番コメントアウト）
        if (oldValue != null && newValue != null) {

          if (newValue <= 0) {//０秒になってもタイマーを止めていないので何度も呼ばれることに注意

            //ページ毎の時間管理なので全体での制限時間オーバーの対処は不要

          }

          //定期的に（client_report_interval_sec間隔）サーバーへ現在の答案一式やページ番号を通知
          // currentPageRemainingSec 基準では意図した間隔で通知できないので、examPartRemainingSec 基準で行う ※０秒を切っても通知し続ける
          if (this.currentMasterExaminationPart.client_report_interval_sec > 0 &&
                      newValue % this.currentMasterExaminationPart.client_report_interval_sec == 0) {
            console.log(`[${LogHeader}] watch:examPartRemainingSec([${oldValue}]→[${newValue}]) attempting to report`);
            this.processPutStudentExamPartWithTmpData(true/*ページと回答に変更がなければスキップ*/);
          }

        }//if(newValue!=null)
      }//handler
    },//examPartRemainingSec
  },//watch
}
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"
.outer-card
  width: 60rem
.card-body
  +mq(max_575)
    padding: 1.0rem 0.5rem
#examination_parts_wrapper
  width: 100%
</style>
