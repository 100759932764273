<template>
  <div>

    <div class="row mt-3">
      <div class="col-12 col-md-auto mx-md-auto col-lg-10 col-xl-7 outer-card">
        <div class="card border-info">

          <div class="card-header text-white bg-nav_header">
            <div class="row">
              <div class="col-12 col-lg-6 _pt-sm-1"><!-- ログアウトボタン等を標準サイズにする場合、この pt-sm-1 は↓のh5を上下中央にする為に必要 -->
                <div class="new_my_h5">WEBテスト 試験結果 試験種 {{ examination? examination.exam_name: '' }}　{{ currentExamPartSetCaption }}</div>
              </div>
              <div v-if="student" class="col-12 col-lg-6 mt-2 mt-lg-0 text-right">
                <div class="new_my_h6">{{ student.school.school_name }}&nbsp;{{ student.school_faculty? `${student.school_faculty.faculty_name} `: '' }}{{ student.school_department? `${student.school_department.department_name} `: '' }}</div>
                <div class="new_my_h6">{{ student.student_no? `${student.student_no}　`: '' }}{{ student.full_name? `${student.full_name} さん `: '' }}</div>
              </div>
            </div>
          </div><!-- card-header -->

          <div class="card-body">

            <div class="row">
              <div class="col-12">
                <StatusMessage/>
              </div>
            </div>

            <template v-if="student">

              <div class="row">
                <div v-if="dispType==DispTypes.result" class="col-12 d-md-flex">
                  <router-link tag="button" :to="{ name: 'home' }" class="btn _btn-sm btn-green mx-2 my-2 px-3">試験トップに戻る</router-link>
                  <a v-if="this.$route.params.examPartSetId=='本テスト'" class="btn _btn-sm btn-orange2 mx-2 my-2 px-4" :href="`/export/personal_report?token=${token}&exam_part_set_id=${this.$route.params.examPartSetId}`" target="_blank">PDF保存</a>
                  <button class="btn _btn-sm btn-light_blue1 mx-2 my-2 px-3" :disabled="!isAvailable" @click.prevent="onShowDesc1">解説を見る</button>
                  <button class="btn _btn-sm btn-light_gray mx-2 ml-md-auto my-2 px-3" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
                </div>
                <div v-else-if="[ DispTypes.desc1, DispTypes.desc2 ].includes(dispType)" class="col-12 d-md-flex">
                  <button class="btn _btn-sm btn-green mx-2 my-2 px-3" :disabled="!isAvailable" @click.prevent="onShowResult">結果表に戻る</button>
                  <button v-if="dispType!=DispTypes.desc1" class="btn _btn-sm btn-green mx-2 my-2 px-3" :disabled="!isAvailable" @click.prevent="onShowDesc1">検査１の解説を見る</button>
                  <button v-if="dispType!=DispTypes.desc2" class="btn _btn-sm btn-green mx-2 my-2 px-3" :disabled="!isAvailable" @click.prevent="onShowDesc2">検査２の解説を見る</button>
                  <router-link tag="button" :to="{ name: 'home' }" class="btn _btn-sm btn-light_blue2 mx-2 my-2 px-3">トップページに戻る</router-link>
                  <button class="btn _btn-sm btn-light_gray mx-2 ml-md-auto my-2 px-3" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
                </div>
              </div>

              <template v-if="dispType==DispTypes.result">

                <div class="row mt-3">
                  <div class="col-12">
                    <component :is="personalReportComponent"
                                :schoolExamStudent="student" :scExStudentExamPartSet="examPartSet"
                    />
                  </div>
                </div>

              </template><!-- DispTypes.result -->
              <template v-else-if="[ DispTypes.desc1, DispTypes.desc2 ].includes(dispType)">

                <div class="row mt-3">
                  <div class="col-12 col-sm-10 offset-sm-1 text-md-right">
                    <button v-for="p in currentMasterExaminationPart.page_count" :key="`page_${p}`" class="btn btn-link my_16px" :class="{ 'font-weight-bold': p==currentPageNo }" @click.prevent="onMovePage(p)">
                      <!--
                      <template v-if="p==1">最初</template>
                      <template v-else-if="p==currentMasterExaminationPart.page_count">最後</template>
                      <template v-else>{{ p }}～</template>
                      -->
                      <template>{{ currentMasterExaminationPart.all_page_params_set[currentMasterExaminationPart.page_names[p-1]].first_q_no }}～</template>
                    </button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-10 offset-sm-1 mt-2" id="examination_parts_wrapper">
                    <div v-if="isDebug" class="debug_str">検査のメインコンポーネント（{{ examPartMainComponent }}）</div>
                    <component :is="examPartMainComponent" :isDebug="isDebug"
                                :examinationPart="currentMasterExaminationPart" :currentPageNo="currentPageNo" :canEdit="false" :showDescription="true"
                                v-model="currentMasterExaminationPart.all_page_params_set"
                    />
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 col-sm-10 offset-sm-1 text-md-right">
                    <button v-if="currentPageNo>1" class="btn btn-header_color mx-1 my-1" @click.prevent="onMovePage(currentPageNo-1)">前の画面へ</button>
                    <button v-if="currentPageNo<currentMasterExaminationPart.page_count" class="btn btn-header_color mx-1 my-1" @click.prevent="onMovePage(currentPageNo+1)">次の画面へ</button>
                  </div>
                </div>

              </template><!-- DispTypes.desc1, desc2 -->

              <div class="row mt-2">
                <div v-if="dispType==DispTypes.result" class="col-12 d-md-flex">
                  <router-link tag="button" :to="{ name: 'home' }" class="btn _btn-sm btn-green mx-2 my-2 px-3">試験トップに戻る</router-link>
                  <a v-if="this.$route.params.examPartSetId=='本テスト'" class="btn _btn-sm btn-orange2 mx-2 my-2 px-4" :href="`/export/personal_report?token=${token}&exam_part_set_id=${this.$route.params.examPartSetId}`" target="_blank">PDF保存</a>
                  <button class="btn _btn-sm btn-light_blue1 mx-2 my-2 px-3" :disabled="!isAvailable" @click.prevent="onShowDesc1">解説を見る</button>
                  <!--
                  <button class="btn _btn-sm btn-light_gray mx-2 ml-md-auto my-2 px-3" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
                  -->
                </div>
                <div v-else-if="[ DispTypes.desc1, DispTypes.desc2 ].includes(dispType)" class="col-12 d-md-flex">
                  <button class="btn _btn-sm btn-green mx-2 my-2 px-3" :disabled="!isAvailable" @click.prevent="onShowResult">結果表に戻る</button>
                  <button v-if="dispType!=DispTypes.desc1" class="btn _btn-sm btn-green mx-2 my-2 px-3" :disabled="!isAvailable" @click.prevent="onShowDesc1">検査１の解説を見る</button>
                  <button v-if="dispType!=DispTypes.desc2" class="btn _btn-sm btn-green mx-2 my-2 px-3" :disabled="!isAvailable" @click.prevent="onShowDesc2">検査２の解説を見る</button>
                  <router-link tag="button" :to="{ name: 'home' }" class="btn _btn-sm btn-light_blue2 mx-2 my-2 px-3">トップページに戻る</router-link>
                  <!--
                  <button class="btn _btn-sm btn-light_gray mx-2 ml-md-auto my-2 px-3" href="#" @click.prevent="onClickLogoutButton">ログアウト</button>
                  -->
                </div>
              </div>

            </template><!-- v-if="student" -->

            <div class="row mt-3">
              <div class="col-12 text-right">
                <img alt="logo" src="../assets/logo.png">
                <span class="mx-1">株式会社サポートシステム</span>
              </div>
            </div>

          </div><!-- card-body -->

        </div><!-- card -->
      </div><!-- col -->
    </div><!-- row -->

  </div>
</template>

<script>
import resourceApi from '../resource_api';
import commonMixin from '../mixins/common';
import wa1_t1 from    '../../common/components/examination_parts/WA-1/本テスト/検査１';
import wa1_t2 from    '../../common/components/examination_parts/WA-1/本テスト/検査２';
import wa1_p1_t1 from '../../common/components/examination_parts/WA-1/練習１/検査１';
import wa1_p1_t2 from '../../common/components/examination_parts/WA-1/練習１/検査２';
import wa1_p2_t1 from '../../common/components/examination_parts/WA-1/練習２/検査１';
import wa1_p2_t2 from '../../common/components/examination_parts/WA-1/練習２/検査２';
import PersonalReport_WA from '../../common/components/personal_report/WA/PersonalReport';
const LogHeader = 'Result.vue';
const DispTypes = {
  result: { title: '個人結果表', },
  desc1: { title: '検査１ 解説', },
  desc2: { title: '検査２ 解説', },
};

export default {
  PageTitle: '個人結果表',
  components: {
    wa1_t1, wa1_t2, wa1_p1_t1, wa1_p1_t2, wa1_p2_t1, wa1_p2_t2,
    //PersonalReport_XXの命名規則は personalReportComponent() を参照のこと
    PersonalReport_WA,
  },
  mixins: [commonMixin],
  data() {
    console.log(`[${LogHeader}] data() CALLED`);
    return {
      token: window.token,//see https://jwt-auth.readthedocs.io/en/develop/quick-start/ > Authenticated requests > Query string parameter
      resourceApi,
      schoolExamination: null,
      examination: null,
      student: null,
      DispTypes,
      dispType: DispTypes.result,
      currentExamPartIdx: null,
      currentPageNo: null,
      testCaptions: {//Home,Exam,Resultで同一の定義
        本テスト: '',
        復習１: '復習利用①',
        復習２: '復習利用②',
        復習３: '復習利用③',
        復習４: '復習利用④',
        練習１: '練習版①',
        練習２: '練習版②',
      },
      isDebug: false,//デバッグ表示用（本番false）
    };
  },
  created() {
    console.log(`[${LogHeader}] created() CALLED`);
    this.resetError();
    this.resetStatus();
    this.setLoadingStatus(true, 'データを取得しています...');
    const url = `school_examination`;
    this.resourceApi
      .getSpecial(url)
      .then((response) => {
        console.log(`[${LogHeader}] created() getSpecial(${url})-then START`, response);
        this.schoolExamination = response.data.school_examination;
        this.examination = response.data.examination;
        this.student = response.data.student;
        this.setLoadingStatus(false);

        //全ての検査が終了していない場合はhomeにリダイレクト
        //本テスト（復習を含む）と１回目の練習は if (!this.examPartSet.finished_at) だけで判定できるが、
        //２回目以降の練習版は開始時にfinished_atを消去してしまう（が、１回目の結果は持っている状態）ので、上記判定条件が使えない
        //なのでfinished_atではなく「個人結果表データを持っているかどうか」で判定するようにした（持っていなければhomeにリダイレクト）
        if (!this.examPartSet.has_personal_report_set) {
          console.log('redirecting result to home');
          this.$router.push({ name: 'home' });
          return;
        }

        //検査１、検査２の正解・解説をall_page_params_setに埋め込み ※検査１、２が先頭から２件であることを前提としている
        //【旧】210806MOD desc_answer導入に伴い、第２引数を追加した
        this.reflectExamPartEvaluationSet(this.examPartSet.master_exam_parts[0], this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[0]);
        this.reflectExamPartEvaluationSet(this.examPartSet.master_exam_parts[1], this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[1]);

        if (this.$route.params.showDescAtFirst) {
          this.onShowDesc1();
        } else {
          this.onShowResult();
        }

        console.log(`[${LogHeader}] created() getSpecial(${url})-then END`);
      }).catch((error) => {
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch START`, error);
        this.setResult(error.response);
        console.error(`[${LogHeader}] created() getSpecial(${url})-catch END`, error);
      });
  },
  mounted() {
    console.log(`[${LogHeader}] mounted() CALLED`);
  },
  // updated() {
  //   console.log(`[${LogHeader}] updated() CALLED`);
  // },
  computed: {
    examPartSet() { return this.student&&this.student.organized_exam_part_sets? this.student.organized_exam_part_sets[this.$route.params.examPartSetId]: null; },
    currentMasterExaminationPart() { return this.examPartSet&&this.currentExamPartIdx>=0? this.examPartSet.master_exam_parts[this.currentExamPartIdx]: null; },
    currentMyExaminationPart() { return this.examPartSet&&this.currentExamPartIdx>=0? this.examPartSet.sc_ex_student_ex_prt_set_exam_parts_asc[this.currentExamPartIdx]: null; },
    examPartMainComponent() { return this.currentMasterExaminationPart? this.currentMasterExaminationPart.main_component_name: null; },
    isLastPage() { return this.currentMasterExaminationPart? (this.currentPageNo == this.currentMasterExaminationPart.page_count ): false; },
    personalReportComponent() { return this.examination? `PersonalReport_${this.examination.exam_name.substr(0,2).toUpperCase()}` :''; },
    currentExamPartSetCaption() { return this.testCaptions[this.$route.params.examPartSetId]; },
  },
  methods: {
    reflectExamPartEvaluationSet(examinationPart, myExaminationPart) {//210806MOD 引数にmyExaminationPartを追加した
      //正解＆解説一式（evaluation_setの各answerとdescription）をall_page_params_setに埋め込む ※admin/views/master/examination_parts/Edit.vueのafterCreatedGetThen()と似たロジック
      //210806MOD 解説時は問題部のチェックボックスには受験者の回答を表示する必要があるので（解説側の正解表示は従来通り）、解説側の正解を desc_answer として分離した
      console.log(`[${LogHeader}] reflectExamPartEvaluationSet() START`);
      for (let pageKey/* p01, p02, ... */ in examinationPart.all_page_params_set) {
        let pageParams = examinationPart.all_page_params_set[pageKey];
        for (let questionKey/* q001, q002, ... *//* s01等も混ざるので注意 */ in pageParams) {
          if (questionKey.match(/^q[0-9]+$/)) {
            let questionParams = pageParams[questionKey];
            console.log(myExaminationPart.result_set);
            questionParams.answer = myExaminationPart.result_set.answer_set[questionKey] ?? [];//210806MOD 受験者の回答内容をセットする（従来は正解をセットしていた） //211014MOD 練習版のmyExaminationPart.answer_setは維持できないのでmyExaminationPart.result_set.answer_setに変更
            questionParams.desc_answer = examinationPart.evaluation_set[questionKey].answer ?? [];//210806ADD
            questionParams.description = examinationPart.evaluation_set[questionKey].description ?? '';
            //console.log(`[${pageKey}][${questionKey}] answer=[${examinationPart.all_page_params_set[pageKey][questionKey].answer}] desc_answer=[${examinationPart.all_page_params_set[pageKey][questionKey].desc_answer}]`);//デバッグ用（本番コメントアウト）
            //console.log(`[${pageKey}][${questionKey}].descrpition=[${examinationPart.all_page_params_set[pageKey][questionKey].description}]`);//デバッグ用（本番コメントアウト）
          }
        }
      }
      console.log(`[${LogHeader}] reflectExamPartEvaluationSet() END`, examinationPart.all_page_params_set);
    },
    onPopState() {
      console.log(`[${LogHeader}] onPopState() CALLED`);
      window.history.pushState(null, null, null);
      alert('ブラウザの「戻る」は使用できません。');
    },
    onMovePage(newPageNo) {
      console.log(`[${LogHeader}] onMovePage(newPageNo=[${newPageNo}]) CALLED`);
      this.currentPageNo = newPageNo;
      window.scrollTo(0, 0);
    },
    onShowResult() {
      console.log(`[${LogHeader}] onShowResult() CALLED`);
      this.dispType = DispTypes.result;
      this.currentExamPartIdx = null;
      this.currentPageNo = null;
      window.scrollTo(0, 0);
    },
    onShowDesc1() {
      console.log(`[${LogHeader}] onShowDesc1() CALLED`);
      this.dispType = DispTypes.desc1;
      this.currentExamPartIdx = 0;
      this.currentPageNo = 1;
      window.scrollTo(0, 0);
    },
    onShowDesc2() {
      console.log(`[${LogHeader}] onShowDesc2() CALLED`);
      this.dispType = DispTypes.desc2;
      this.currentExamPartIdx = 1;
      this.currentPageNo = 1;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../common/sass/_base"
.outer-card
  width: 80rem
.card-body
  +mq(max_575)
    padding: 1.0rem 0.5rem
#examination_parts_wrapper
  width: 100%
</style>
